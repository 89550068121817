import { AplicationErrorHandler } from './../../../../app.error-handler';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AddressClientService {
  static OrderService: any;


    constructor(
        public http:HttpClient,
        public auth:AuthService
    ) {}



    saveAddress(endereco:any):Observable<any>{
        return this.http.post<any>(`${environment.URL_API}/create/address`,endereco)
        .catch(AplicationErrorHandler.handleError)
        .map(response =>response)
    }


    updateAddress(endereco:any):Observable<any> {
        return this.http.post<any>(`${environment.URL_API}/update/address/${endereco.id}`,endereco)
        .catch(AplicationErrorHandler.handleError)
        .map(result => result);
    }

    deleteAddress(idAddress: number): Observable<any> {
        return this.http.post<any>(`${environment.URL_API}/status/address/${idAddress}`,{status:'EXCLUIDO'})
        .catch(AplicationErrorHandler.handleError)
        .map(result => result)
    }

    allUserAddress():Observable<any>{
        return this.http.get<any>(`${environment.URL_API}/user/addresses/${this.auth.currentUser.id}`)
        .catch(AplicationErrorHandler.handleError)
        .map(response => response.data);
    }

    // enderecoPorId(id?):Observable<any>{
    //     return this.http.get<any>(`${environment.URL_API}/enderecos?id=${id}`)
    //     .catch(AplicationErrorHandler.handleError)
    //     .map(resposta => resposta.data);
    // }

    // addressState(state:string): Observable<any> {
    //  return this.http.get<any>(`${environment.URL_API}/enderecos?estado=${state}&user_id=${this.auth.currentUser.id}`).map(resposta=>resposta.data);
    // }

    // addressById(id:number): Observable<any> {
    //     return this.http.get<any>(`${environment.URL_API}/enderecos?id=${id}`).map(resposta=>resposta.data);
    // }

    // getAllAddressOfClient(id:number):Observable<any>{
    //     return this.http.get<any>(`${environment.URL_API}/user/address/${id}`) 
    //     .catch(AplicationErrorHandler.handleError)
    //     .map(resposta=>resposta.data);
    // }

    // getAllAddressWithOrders(id:number): Observable<any> {
    //     return this.http.get<any>(`${environment.URL_API}/get-all-address-with-orders/${id}`) 
    //     .catch(AplicationErrorHandler.handleError)
    //     .map(resposta=>resposta.data);
    // }

}
