import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { AplicationErrorHandler } from 'src/app/app.error-handler';
import { environment } from 'src/environments/environment';

@Injectable()
export class MenuService {
  



  constructor(public http: HttpClient, public auth: AuthService) { }

  // deleteMenu(arg0: any): any {
    // return this.http.post<any>(`${environment.URL_API}/update/restaurant/password/${user.id}`, user)
    //     .catch(AplicationErrorHandler.handleError)
    //     .map((response)=>{
    //         return response.mensagem
    //     })
  // }

  saveMenu(menu: any): Observable<any> {
    return this.http.post<any>(`${environment.URL_API}/create/product`, menu)
      .catch(AplicationErrorHandler.handleError)
      .map((response) => {
        return response.message
      })
  }

  getProductsInPromotion(idpromotion: any) {
    return this.http.get<any>(`${environment.URL_API}/get-products-in-promotions/${idpromotion}`)
      .catch(AplicationErrorHandler.handleError)
      .map((response) => {
        return response.data
      })
  }

  savePromotionProduct(promotion): Observable<any> {
    return this.http.post<any>(`${environment.URL_API}/create/promotion-product`, promotion)
      .catch(AplicationErrorHandler.handleError)
      .map((response) => {
        return response.message
      })
  }
  updatePromotionProduct(promotion): Observable<any> {
    return this.http.post<any>(`${environment.URL_API}/update/promotion-product`, promotion)
      .catch(AplicationErrorHandler.handleError)
      .map((response) => {
        return response.message
      })
  }

  savePromotion(promotion: any): Observable<any>  {
    return this.http.post<any>(`${environment.URL_API}/create/promotion`, promotion)
    .catch(AplicationErrorHandler.handleError)
    .map((response) => {
      return response.message
    })
  }
  getPromotions(idRestaurant?:number): Observable<any>  {
    return this.http.get<any>(`${environment.URL_API}/promotions/${this.auth.currentUser.idrestaurant || idRestaurant}`)
    .catch(AplicationErrorHandler.handleError)
    .map((response) => {
      return response.data
    })
  }

  getPromoProducts(idRestaurant?:number) {
    return this.http.get<any>(`${environment.URL_API}/get-promo-products/${this.auth.currentUser.idrestaurant || idRestaurant}`)
    .catch(AplicationErrorHandler.handleError)
    .map((response) => {
      return response.data
    })
  }

  getPromotionInPromotions(idProduct): Observable<any>  {
    return this.http.get<any>(`${environment.URL_API}/get-promotion-in-promotions/${idProduct}`)
    .catch(AplicationErrorHandler.handleError)
    .map((response) => {
      return response.data
    })
  }

  deleteProductOnPromotion(idproduct: any, idpromotion: any):Observable<any>  {
    return this.http.get<any>(`${environment.URL_API}/delete-product-in-promotions/${idproduct}/${idpromotion}`)
    .catch(AplicationErrorHandler.handleError)
    .map((response) => {
      return response.data
    })
  }


  updateMenu(menu: any): Observable<any> {
    return this.http.post<any>(`${environment.URL_API}/update/product/${menu.get('id')}`, menu)
      .catch(AplicationErrorHandler.handleError)
      .map((response) => {
        return response.message
      })
  }
  updateMenuStatus(idProduct: any, status: any): Observable<any> {
    return this.http.post<any>(`${environment.URL_API}/status/product/${idProduct}`, { status: status })
      .catch(AplicationErrorHandler.handleError)
      .map((response) => {
        return response.mensagem
      })
  }

  updatePromotionStatus(idPromotion: number, status: any): Observable<any> {
    return this.http.post<any>(`${environment.URL_API}/status/promotion/${idPromotion}`, { status: status })
      .catch(AplicationErrorHandler.handleError)
      .map((response) => {
        return response.message
    })
  }

  updatePromotion(promotion: any): Observable<any> {
    return this.http.post<any>(`${environment.URL_API}/update/promotion/${promotion.id}`, promotion)
      .catch(AplicationErrorHandler.handleError)
      .map((response) => {
        return response.message
      })
  }

  allCategories(): any {
    return this.http.get<any>(`${environment.URL_API}/categories`)
      .catch(AplicationErrorHandler.handleError)
      .map((response) => {
        return response.data
      })
  }

}
