import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CartItem } from 'src/app/pages/order/shared/models/cart-item-model';
import { Observable } from 'rxjs/RX';
import { Order } from 'src/app/pages/order/shared/models/order.model';
import { environment } from 'src/environments/environment';
import { ShoppingCartService } from '../../../../core/components/shared/service/shopping-cart.service';

@Injectable()
export class OrderService {



    constructor(
        public cartService:ShoppingCartService,
        public auth:AuthService,
        public http:HttpClient
    ){}

    cartItems():CartItem[]{
        return this.cartService.items;
    }

    increaseQtd(item:CartItem):void{
        this.cartService.increaseQtd(item);
    }

    decreaseQtd(item:CartItem):void{
        this.cartService.decreaseQtd(item);
    }

    remove(item:CartItem){
        this.cartService.removeItem(item);
    }

    itemsValue():number{
        return this.cartService.total();
    }

    clear(){
        this.cartService.clear();
    }

    saveOrder(order:any):Observable<any>{
        return this.http.post<any>(`${environment.URL_API}/create/order`,order)
        .map(response=>response.data)
    }

    saveComment(comment: any): Observable<any> {
        return this.http.post<any>(`${environment.URL_API}/create/evaluation`,comment)
        .map(response=>response.mensagem)
    }




    getOrderId():Observable<string>{
        return this.http.get<any>(`${environment.URL_API}/orders/code`)
        .map(response => response.data);
    }

    allUserOrders():Observable<Order[]>{
        return this.http.get<any>(`${environment.URL_API}/user/orders/${this.auth.currentUser.id}`)
        .map(response => response.data);
    }

    allRestaurantsEvaluations():Observable<any> {
      return this.http.get<any>(`${environment.URL_API}/get-restaurant-evaluated-by-user/${this.auth.currentUser.id}`)
      .map(response => response);
    }

    orderById(id):Observable<Order>{
        return this.http.get<any>(`${environment.URL_API}/orders?id=${id}`)
        .map(response =>response.data);
    }
    orderItems(idOrder: any): Observable<any> {
        return this.http.get<any>(`${environment.URL_API}/order/items/${idOrder}`).map(response=>response.data)
    }
}
